.partners {
    position: absolute;
    top: 5%;
    left: 20%;
    width: 75%;
    bottom: 5%;
    .title {
        transition: .3s ease-in-out;
        position: fixed;
        min-width: 400px;
        max-width: 450px;
        text-align: left;
        background-color: #0b9444;
        padding: 20px 80px;
        border-radius: 0 50px 50px 0;
        color: white;
        z-index: 10;
        filter: drop-shadow(0 0 0.75rem gray);
        // position: sticky;
        top: 5% ;
        // left: 0;
    }
.stockNav {
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
    button {
        border-radius: 10px;
        margin-right: 10px;
        padding: 5px 10px;
        border: unset;
        &:hover {
            background-color: #0b9444;
            color: white;
        }
    }
    .active {
        background-color: #0b9444;
        color: white;
    }
}
.listimi {
 margin-top: 20px;
 margin-left: 30px;
 margin-bottom: 50px;
 text-align: left;  
 .list-item-head {
    background-color: #0b9444;
    color: white;
    font-weight: 700;
    padding:10px 0;
    position: fixed;
    left: 22%;
    right: 2%;
    // bottom: 4%;
    // width: 100%;
    top: 20%;
    // float: left;
}
.tableContainer {
    position: fixed;
    right: 0;
    bottom: 4%;
    left: 22%;
    top: 26%;
    height: auto;
    overflow-y: auto;
.list-item {
    width: 100%;
    padding:10px 0;
    margin-left: 10px;
    width: 97%;
    background-color: white;
    &:hover{
        filter: drop-shadow(0 0 0.45rem gray);
        margin-bottom: 2px;
    }

}
}
}
}

.product-img {
    width: 150px;
    border-radius: 50%;
    border: 10px gray solid;
}