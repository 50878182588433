.homepage {
    display: block;
    z-index: 1;
    width: 100%;
// background: black;
    .heading {
        position: absolute;
        padding-top: 320px;
        height: 700px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
        img {object-fit: cover; height: 700px;}
        @media only screen and (max-width: 600px) {
            padding-top: 250px;
            }
        .homeSlogan {
            padding-top: 20px;
            padding-bottom: 50px;
            text-transform: uppercase;
            font-family: sans-serif !important;
            font-weight: 400;
            color: white;
            filter: drop-shadow(0 0 0.75rem gray);

            
        }
        .homeAboutButton {
            background-color: #0b9444;
            color: white;
            padding: 10px 20px;
            margin-right: 20px;
            border-radius: 5px;
            transition: .3s ease-in-out;
            font-weight: 500;
            text-transform: uppercase;
            &:hover{
                padding: 10px 40px; 
                text-decoration: none;
            }
        }
        .homeProductButton {
            background-color: red;
            border-radius: 5px;
            color: white;
            padding: 10px 20px;
            margin-right: 20px;
            transition: .3s ease-in-out;
            font-weight: 500;
            text-transform: uppercase;

            &:hover{
                padding: 10px 40px; 
                text-decoration: none;
            }
        }
    }
    .slider-wrapper {
        height: 700px !important;
        img {
            height: 700px !important;
            object-fit: cover;
        }
        mix-blend-mode: darken ;
        @media only screen and (max-width: 600px) {
            img {
                height: 700px;
                object-fit: cover;
            }
        }

    }
    .products {
        position: relative;
        left: 0;
        right: 0;
        width: 80%;
        margin: 0 auto;
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
        // margin-top: 30px;
        // height: 900px;
        // background-color: black;
        .productListing {
            @media only screen and (max-width: 600px) {  width: 100%; }
            
            width: 45%;
            float: left;
            background-color: #f1f1f1;
            margin-bottom: 10px;
            margin-left: 10px;
            margin-right: 10px;
            border-radius: 50px;
            overflow: hidden ;
            transition: ease-in-out .3s;
            height: 230px;
            

            .productImage {
                overflow: hidden;
                height: 230px;
                margin-left: -20px;
                img {
                    @media only screen and (max-width: 600px) {  
                        width: 180px; 
                        height: 230px;
                        object-fit:cover;
                transition: ease-in-out .1s;
                    
                    }

                overflow: hidden;
                width: 230px;
                object-fit: contain;
                transition: ease-in-out .1s;

                }
                .productPrice{
                    // padding-right: 15px;
                    
                    @media only screen and (max-width: 600px) { 
                        font-size: 10px; 
                        span {
                            font-size: 10px; 

                        }
                    }
                    span {

                        z-index: 99999;
                        left: 0;
                        top:0;
                        bottom: 0;
                        right: 0;
                        // padding: 10px;
                        font-size: 16px;
                        display: block;
                        background-color: red;
                        color: white;
                        height: auto;
                        padding: 10px ;
                        
                    }
                }
            }
            .productContent {
                border-left: 15px solid #0b9444;
                transition: ease-in-out .3s;
                text-align: left;
                width: 45%;
    
                .productName {
                    font-size: 34px;
                    text-transform: uppercase;
                    color: #0b9444;
                    transition: ease-in-out .3s;
                    padding-left: 8px;
                    padding-top: 8px;
                }

                .productCapacity {
                    text-align: left;
                    padding: 5px;
                    padding-left: 8px;
                    // color: ;
                }
                .productCapacityValue {
                    width: 150px;
                    padding: 5px;
                    padding-left: 8px;
                    background: #0b9444;
                    border-radius: 0 10px 10px 0;
                    color: white;
                    font-weight: 600;
                }
                
            }
            &:hover, &:focus {
                background-color: #0b9444;
                filter: drop-shadow(0 0 0.75rem gray);
                overflow: hidden !important;
                // margin-left: 20px;
                // width: 46%;

                .productName { 
                    color: white;
                    filter: drop-shadow(0 0 0.75rem gray);

                }
                .productUpdate {
                    position: absolute;
                    // width: 200px;
                    text-align: right;
                    right: 43%;
                    bottom: 0;
                    padding: 5px 20px;
                    background: #0b9444;
                    color: white;
                    border-radius: 10px 10px 0 0;
                    filter: drop-shadow(0 0 0.75rem gray);


                }
                .productPrice {
                    position: absolute;
                    width: 57%;
                    text-align: right;
                    color: white;
                    background-color: red;
                    font-size: 22px;
                    overflow: none;
                    padding: 5px;
                    padding-right: 10px;
                    
                    
                    span{
                        filter: drop-shadow(0 0 1rem gray);
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        top:0;
                        width: 60%;
                        text-align: right;
                        display: inline;
                        padding: 10px;
                        background: #0b9444;
                        font-size: 16px;

                    }
                }
                .productImage { 
                    width: 60% !important; 
                    float: left; 
                    // overflow:hidden; 
                    text-align: left;
                    overflow: hidden !important;
                    
                    img {
                        width: 300px;

                    }
                }
                .productContent { 
                    // position: relative;
                    border-left: 15px solid white;
                    width: 30%;  
                    // float: left ;
                    z-index: 999999;
                    margin-left: -3px;
                    
                    .productCapacityValue {
                        background-color: white;
                        color: #0b9444;
                        filter: drop-shadow(0 0 0.75rem gray);
                        overflow: hidden !important;
                        
                    }
                    .productCapacity {
                        overflow: hidden !important;
                        color: white;
                    }
                }
            }
    
        } 
    }
    .about {
        position: relative;
        left: 0;
        width: 100%;
        // height: 900px;
        background-color: #272727;
        .aboutRow {
            // font-family: 'Montserrat', 'sans-serif';
            padding: 20px;
            padding-bottom: 50px;
        }
        .aboutContent {
            text-align:justify;
            line-height: 2rem;
            padding-top: 15px;
            font-size: 22px;
            color: #939393;
        }
        .aboutSlogan {
            text-align: right;
            font-size: 50px;
            filter: drop-shadow(0 0 0.1rem #272727);
            font-weight: 200;
            padding-left: 50px;

        }
    }
    .outTeam {
        position: relative;
        width: 100%;
        // height: 900px;
        background-color: #0b9444;
        .memberListing {
            width: 25%;
            margin-bottom: 20px;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }

            .memberImage {
                width: 100%;
                // overflow: hidden;
                img {
                    border-radius: 50%;
                    width: 250px; 
                    height: 250px;
                    transition: .3s ease-in-out;
                    // &:hover {
                    //     border: 10px solid white;
                    // }
                }
            }
            .memberContent {
                h4 {
                    // font-size: 28px;
                    font-weight: 600;
                }
                padding: 10px;
                padding-top: 30px;
                color: white;
                transition: .3s ease-in-out;
            }

            &:hover {
                filter: drop-shadow(0 0 0.9rem #00000077);
                .memberImage {
                    border-radius: 20px 20px 0 0 ;
                    // border: 5px solid white;
                    background: white;
                    overflow: hidden;
                    img { 
                        // border: 10px solid white;
                        width: 110%;
                        object-fit: cover;
                        // height: 100%;
                        border-radius: unset;

                    }
                }
                .memberContent {
                    filter: drop-shadow(0 0 0.1rem #b3b1b1);
                    padding-bottom: 40px;
                    background-color: white;
                    color: #0b9444;
                    z-index: -1;
                    border-radius:  0 0 20px 20px;
                }
            }
        }
        
    }
    .contact {
        position: relative;
        width: 100%;
        // height: 900px;
        background-color: #f1f1f1;
        color: #0b9444;
        .pageTitle {
            // color: black;
            
        }
        .pageSlogan {
            // color: black;

        }
        form {
            label { width: 80%; text-align: left; line-height: 1px; }
            input { width: 80%; border-radius: 10px; margin-bottom: 5px; border:unset; padding: 5px 15px; &:focus {outline: 2px solid #0b9444;}}
            textarea { width: 80%; height:200px; border-radius: 10px; margin-bottom: 5px; border:unset; padding: 5px 15px;&:focus {outline: 2px solid #0b9444;}}
            button { width: 80%; border-radius: 10px; margin-bottom: 5px; border:unset; padding: 5px 15px; background-color: #0b9444; color: white; }
       
            @media only screen and (max-width: 600px) {
                margin-bottom: 30px;
                label, input, textarea, button {
                    width: 100%;
                }
              }
        }
        .recaptcha {
            position: absolute;
            bottom: 6%;
            left: 0;
        }
    }
    @media only screen and (max-width: 600px) {
        .footer {

            // text-align: center !important;
            padding-left: 70px;
        }
        // .social {
        //     text-align: center !important;
        // }
      }
    .footer {
        // border-radius: 40px 40px 0 0 ;
         li {
            list-style: none;
        }
        b { color: #ffffff8e; font-size: 22px;}
        a { color: white;}
        ul { margin-left: -40px; transition: .3s ease-in-out; }
        a:hover { color: #0b9444; text-decoration: none; font-weight: 700; border-left: 3px solid; padding-left: 5px;}
        .fa {font-size: 24px !important;}
        .social {
            // b {text-align: left !important;}
            text-align: left;
            padding-bottom: 20px;
            // a {padding-top: 10px !important;}
            .fa {font-size: 40px !important; margin-left: 5px; padding-top: 30px;}
            a:hover {color: #0b9444; text-decoration: none; font-weight: inherit ; border-left: unset; padding-left: unset;}
        }
    }
    .pageTitle {
        padding-top: 80px ;
        text-transform: uppercase;
        font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        font-weight: 100;
    }
    .pageSlogan {

    }
    
    .control-dots {
        z-index: 999;
    }
}
