.shitjet {
    position: absolute;
    top: 5%;
    left: 20%;
    width: 75%;
    bottom: 5%;
    .title {
        transition: .3s ease-in-out;
        position: fixed;
        min-width: 400px;
        max-width: 450px;
        text-align: left;
        background-color: #0b9444;
        padding: 20px 80px;
        border-radius: 0 50px 50px 0;
        color: white;
        z-index: 10;
        filter: drop-shadow(0 0 0.75rem gray);
        // position: sticky;
        top: 5% ;
        // left: 0;
    }
.stockNav {
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
    button {
        border-radius: 10px;
        margin-right: 10px;
        padding: 5px 10px;
        border: unset;
        &:hover {
            background-color: #0b9444;
            color: white;
        }
    }
    .active {
        background-color: #0b9444;
        color: white;
    }
}
.listimi {
 margin-top: 20px;
 margin-left: 30px;
 margin-bottom: 50px;
 text-align: left;  
 .list-item-head {
    background-color: #0b9444;
    color: white;
    font-weight: 700;
    padding:10px 0;
    position: fixed;
    left: 22%;
    right: 2%;
    // bottom: 4%;
    // width: 100%;
    top: 20%;
    // float: left;
}
.tableContainer {
    position: fixed;
    right: 0;
    bottom: 4%;
    left: 22%;
    top: 26%;
    height: auto;
    overflow-y: auto;
.list-item {
    // position: absolute;
    // top: calc(1vw + 45%);
    // display: table-column;
    // left: calc(1vw + 21%);
    // right: calc(1vw + 0);
    // height: 100%;
    // bottom: 5%;
    width: 100%;
    padding:10px 0;
    margin-left: 10px;
    width: 97%;
    background-color: white;
    &:hover{
        // border-left: calc(1vw + 5px  #0b9444 solid);

        filter: drop-shadow(0 0 0.45rem gray);
        margin-bottom: 2px;
    }

}
}
}
}

.product-img {
    width: 150px;
    border-radius: 50%;
    border: 10px gray solid;
}
.productsToSelect {
    width: 100%;
    background-color: #0b9444 !important;
    position: absolute;
    // left: 13%;
    // right: 13%;
    padding: 20px;
    color: white;
    border-radius: 20px;
}
    .selectProductItemn {
        display:inline-block;
        background-color: #f1f1f1;
        border-radius: 20px;
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 5px 15px ;
        width: auto;
        float: left;
        color:#0b9444;
        // transition: .3s ease-in-out;
        &:hover {
            // font-weight: 900;
            font-weight: 600;
            background-color: rgba(255, 255, 255, 0.613);
            // color: white;
            cursor: pointer;
            filter: drop-shadow(0 0 0.45rem #0b9444);
        }
    }

    .dropItemsHolder {
        // width: 90%;
        height: 100%;
        padding: 20px 20px;
        // background-color: #0b94441a !important;
        position: relative;
        margin-left: 20px;
        // left: 13%;
        // right: 13%;
        h5 {
            padding: 20px 30px;
            
        }
        color: #0b9444;
        border: 3px #0b9444 dotted ;
        border-radius: 20px;
    }
    .dropItemsWrapper {
        
        // padding: 20px 20px;
        background-color: white !important;
        position: relative;
        height: auto;
        width: 100%;
        color: #0b9444;
        // border: 3px #0b9444 dotted ;
        border-radius: 20px;
    }
    .dropItem {
        // display:inline-block;
        background-color: #f1f1f1;
        border-radius: 10px;
        margin-bottom: 5px;
        // margin-left: 5px;
        // margin-right: 5px;
        // margin-bottom: 5px;
        // padding: 5px 15px ;
        padding: 10px 10px;

        width: 100%;
        float: left;
        color:#0b9444;
        // transition: .3s ease-in-out;
    
        input {width: 100%;}
        &:hover {
            // font-weight: 900;
            font-weight: 600;
            background-color: rgba(255, 255, 255, 0.613);
            // color: white;
            cursor: pointer;
            filter: drop-shadow(0 0 0.25rem #9e9b9b);
        }
    }
