.progress-wrapper {
    // border: 3px solid blue;
    // border-radius: 20px 20px 20px 20px;


.progress-container {
      border-radius: 50px ;
      background-color: #942d0b;
    }
    
    .progress-bar {
      border-radius: 20px 0 0 20px;
    background-color: #0b9444;
    width: 80%;
  }
  
  .progress-label {
    position: absolute;
    right: 10%;
    top: 42%;
    // font-size: 20px;
    color: white;
  }

  .list-item {
    padding:5px 0;
    width: 99%;
    margin: auto;
    background-color: white;
    &:hover{
        filter: drop-shadow(0 0 0.45rem gray);
        margin-bottom: 2px;
    }

}

.list-item-head {
    background-color: #0b9444;
    color: white;
    font-weight: 700;
    padding:10px 0;
    width: 99%;
    margin: auto;
}
}