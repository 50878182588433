.statistics {
    position: absolute;
    left: 19%;
    .dateShow {
        position: fixed;
        bottom: 10%;
        z-index: 999999;
        color: #FFF;
    }
    .fromTo {
        position: fixed;
        // min-width: 250px;
        // width: 500px;
        // max-width: 550px;
        right: 0;
        top: 3px;
        left: 45%;
        height: 40px;
        border-radius: 50px 0 0 50px;
        background-color: #0b9444;
        color: white;
        z-index: 9;
        filter: drop-shadow(0 0 0.15rem gray);
        .refresh {
            position: absolute;
            left: 0;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            background-color: #0b9444;
            color: white;
            i {
                font-size: 25px; 
                line-height: unset;
                padding: 2px;
                transition: .3s ease-in-out;
                &:hover{
                rotate: 360deg;
                color: yellowgreen;
                }
            }
            button {
                color: white;
                border: unset;
                background: unset;
                line-height: unset;
            }
            &:hover{
                height: 45px;
                width: 45px;
                left: -2px;
                top: -2px;
     
            }
        }
        .dates {
            padding: 5px;
            label {
                width: 20%;
            }
            input {
                width: 30%;
                background-color: unset;
                border: #FFF 1px solid;
                color: #FFF;
                border-radius: 20px;
                padding: 3px 10px;
            }
        }
    }
    .title {
        transition: .3s ease-in-out;
        position: fixed;
        min-width: 400px;
        max-width: 450px;
        text-align: left;
        background-color: #0b9444;
        padding: 20px 80px;
        border-radius: 0 50px 50px 0;
        color: white;
        z-index: 10;
        filter: drop-shadow(0 0 0.75rem gray);
        // position: sticky;
        top: 5% ;
        // left: 0;
    }
    .statisticContainer {
        position: fixed;
        top: 17%;
        right: 0;
        bottom: 0;
        // left: 20% !important;
        height: auto;
        overflow-y: auto;
        width: 80%;
        padding: 20px;
        .dashboardItem {
            width: 98%;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            float: left;
            border-radius: 12px;
        }
    }
}
