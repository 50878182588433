.bashkpunetoret {
    position: absolute;
    top: 5%;
    left: 20%;
    width: 75%;
.stockNav {
    text-align: left;
    margin-top: 100px;
    padding-left: 20px;
    button {
        border-radius: 10px;
        margin-right: 10px;
        padding: 5px 10px;
        border: unset;
        &:hover {
            background-color: #0b9444;
            color: white;
        }
    }
    .active {
        background-color: #0b9444;
        color: white;
    }
}
.listimi {
 margin-top: 20px;
 margin-left: 30px;
 margin-bottom: 50px;
 text-align: left;  
 .list-item-head {
    background-color: #0b9444;
    color: white;
    font-weight: 700;
    padding:10px 0;
    position: fixed;
    left: 22%;
    right: 2%;
    // bottom: 4%;
    top: calc(1vw + 25%);
}
.tableContainer {
    position: fixed;
    right: 0;
    bottom: 4%;
    left: 22%;
    top: 35%;
    height: auto;
    overflow-y: auto;
.list-item {
    // position: absolute;
    // top: calc(1vw + 45%);
    // display: table-column;
    // left: calc(1vw + 21%);
    // right: calc(1vw + 0);
    // height: 100%;
    // bottom: 5%;
    padding:10px 0;
    margin-left: 10px;
    width: 97%;
    background-color: white;
    &:hover{
        // border-left: calc(1vw + 5px  #0b9444 solid);

        filter: drop-shadow(0 0 0.45rem gray);
        margin-bottom: 2px;
    }

}
}
}
}

.product-img {
    width: 150px;
    border-radius: 50%;
    border: 10px gray solid;
}