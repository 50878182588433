.stock {
    position: absolute;
    // top: calc(1vw + 5%);
    left: calc(1vw + 20%);
    width: calc(1vw + 75%);
    // position: fixed;
    top: calc(1vw + 35%);
    .title {
        transition: .3s ease-in-out;
        position: fixed;
        min-width: 400px;
        max-width: 450px;
        text-align: left;
        background-color: #0b9444;
        padding: 20px 80px;
        border-radius: 0 50px 50px 0;
        color: white;
        z-index: 10;
        filter: drop-shadow(0 0 0.75rem gray);
        // position: sticky;
        top: 5% ;
        // left: 0;
    }
.stockNav {
    text-align: left;

    position: fixed;
    top: 18%;
    left: 21%;
    // z-index: 99999999999;
    // filter: drop-shadow(0 1px 0.50rem gray);

    button {
        border-radius: 10px;
        margin-right: calc(1vw + 10px);
        padding: 5px 10px;
        background-color: gainsboro;
        border: unset;
        &:hover {
            background-color: #0b9444;
            color: white;
        }
    }
    .active {
        background-color: #0b9444;
        color: white;
    }
}
.listimi {
 margin-top: calc(1vw + 20px);
 margin-left: calc(1vw + 30px);
 margin-bottom: 50px;
 text-align: left;
    .list-item-head {
        background-color: #0b9444;
        color: white;
        font-weight: 700;
        padding:10px 0;
        position: fixed;
        left: calc(1vw + 22%);
        right: 2%;
        // bottom: 4%;
        top: calc(1vw + 25%);
    }
    .tableContainer {
        position: fixed;
        right: 0;
        bottom: 4%;
        left: 22%;
        top: 35%;
        height: auto;
        overflow-y: auto;
    .list-item {
        // position: absolute;
        // top: calc(1vw + 45%);
        // display: table-column;
        // left: calc(1vw + 21%);
        // right: calc(1vw + 0);
        // height: 100%;
        // bottom: 5%;
        padding:10px 0;
        margin-left: 10px;
        width: 97%;
        background-color: white;
        &:hover{
            // border-left: calc(1vw + 5px  #0b9444 solid);

            filter: drop-shadow(0 0 0.45rem gray);
            margin-bottom: 2px;
        }

    }
    }
}
}

.product-img {
    width: calc(1vw + 150px);
    border-radius: 50%;
    border: 10px gray solid;
}