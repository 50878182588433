.menu {
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    height: 80px;
    background-color: white;
    filter: drop-shadow(0 0 0.75rem gray);
    z-index: 9999;

    a {
        color: gray;
        font-weight: 600;
        &:hover{
            color: green;
        }
    }
    @media only screen and (max-width: 750px) {
        display: none;
      }

}

.mobile-menu {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    height: 80px;
    background-color: white;
    filter: drop-shadow(0 0 0.75rem gray);
    z-index: 9999;
    a {
        color: gray;
        font-weight: 600;
        &:hover{
            color: green;
        }
    }
    .mobileMenuWrapper {
        position: fixed;
        top: 80px;
        left:0;
        right:0;
        height: auto;
        background-color: white;
        a {
            width: 100%;
            float: left;
            padding: 10px 20px;
        }
        a:hover {
            background-color: green;
            color: white;
        }
    }
    @media only screen and (max-width: 750px) {
        display: inline-block;
      }
}

.admin-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 20%;
    height: auto;
    overflow-y: auto;
    background-color: white;
    color: #0b9444;
    z-index: 9999; 
    // filter: drop-shadow(-30px -30px -30px gray);
    box-shadow: inset -3px 0px 28px gray;
    
    @media only screen and (max-width: 750px) {
        // display: inline-block;
        width: 100% !important;
        right: 0;
        z-index: 99;
      }
    img {
        position: absolute;
        // width: 20%;
        left: 15%;
        padding: 20px;
        height: auto; 
        object-fit: cover;
        // box-shadow:  0 6px 28px gray;
    }
    ul {
        // position: fixed;
        // // width: 100%;
        // left: 0;
        // top: 25%;
        // bottom: 0px;
        margin-top: 70%;
        height: auto;
        overflow-y: auto;
        // margin-top: 15px;
        list-style-type: none;
        text-align: left;
        @media only screen and (max-width: 750px) {
            margin-top: 180px;
        }
        .subMenuItems {
            // margin-left: 20px;
            // width: 80%;
            padding-left: 20px;
            // a {
            //     li {
            //     //     transition: .3s ease-in-out;
            //     //     padding: 10px 25px;
            //     //     color: #0b9444;
            //     }

            //     // &:hover {
            //     //     // a {color: white}
            //     //     background-color: #0b9444;
            //     //     color: white;
            //     //     padding-left: 30px;
            //     // }

            // }
        }
        a {
            color: #0b9444;
            text-decoration: none;
            li.active {
                // a {color: white}
                background-color: #0b9444;
                color: white;
                padding-left: 30px;
            }
            li {
                transition: .3s ease-in-out;
                padding: 10px 15px;
                color: #0b9444;
                

                &:hover {
                    // a {color: white}
                    background-color: #0b9444;
                    color: white;
                    padding-left: 30px;
                }
            }
            &:hover {
                // a {color: white}
                background-color: #0b9444;
                color: white;
            }
        }
    }
}
.menuButton {
    position: fixed;
    top: 7%;
    // left: 21%;
    z-index: 99999;
    border-radius: 50%;
    background-color: #FFF;
    color: #0b9444;
    border: unset;
    width:50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    border: 4px #0b9444 solid;
    transition: .3s ease-in-out;
    font-size: 22px;
    
    &:hover{
        
        filter: drop-shadow(0 0.25rem 0.75rem gray);
        background-color: #0b9444;
        border: 5px #FFF solid;
        color: #FFF;
    }
}