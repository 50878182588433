.dashboard {
    position: absolute;
    // top: calc(1vw + 5%);
    left: calc(1vw + 19%);
    // width: calc(1vw + 75%);
    // // position: fixed;
    // top: calc(1vw + 0%);
    
    .title {
        transition: .3s ease-in-out;
        position: fixed;
        min-width: 400px;
        max-width: 450px;
        text-align: left;
        background-color: #0b9444;
        padding: 20px 80px;
        border-radius: 0 50px 50px 0;
        color: white;
        z-index: 10;
        filter: drop-shadow(0 0 0.75rem gray);
        // position: sticky;
        top: 5% ;
        // left: 0;
    }
    .dashboardContainer {
        transition: .3s ease-in-out;

        position: fixed;
        top: 15%;
        right: 0;
        bottom: 0;
        // left: 20% !important;
        height: auto;
        overflow-y: auto;
        width: 80%;
        padding: 20px;
        .dashboardItem {
            width: 98%;
            padding: 20px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            float: left;
            border-radius: 12px;
        }
    }
}