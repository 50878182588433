.App {
  text-align: center;
  // background-image: url('./images/home-nl-2-07.png');
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.pages {
  // position: fixed;
  // top: 10%;
  // left:0;
  // right:0;
  // bottom: 0;
  // height: auto;
  // overflow-y: auto;
  // filter: drop-shadow(0 0 0.75rem gray);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.title {
  transition: .3s ease-in-out;
  position: fixed;
  min-width: 400px;
  max-width: 450px;
  text-align: left;
  background-color: #0b9444;
  padding: 20px 80px;
  border-radius: 0 50px 50px 0;
  color: white;
  z-index: 10;
  filter: drop-shadow(0 0 0.75rem gray);
  // position: sticky;
  top: 5% ;
  // left: 0;
  @media only screen and (max-width: 750px) {
    // display: inline-block;
    // width: 100% !important;
    right: 5% !important;
  }
}

.admin-containter {

    position: fixed;
    top: 5%;
    left: 25%;

}

// .addButton {
//   position: fixed;
//   top: 10%;
//   right: 12%;
//   // padding: 10px;
//   height: 50px;
//   width: 50px;
//   border-radius: 50%;
//   background-color: #0b9444;
//   color: white;
//   font-size: 32px;
//   border: unset;
//   line-height: 5%;

//   // position: fixed;
// bottom: 5%;
//   &:hover {

//     filter: drop-shadow(0 0 0.75rem gray);
//   }

// }

.addButton {
   position: fixed;
    bottom: 2%;
    right: 2%;
    z-index: 1;
    border-radius: 50%;
    background-color: #FFF;
    color: #0b9444;
    border: unset;
    width:50px !important;
    height: 50px !important;
    border-radius: 50% !important;
    border: 4px #0b9444 solid;
    transition: .3s ease-in-out;
    font-size: 42px;
    line-height: 0px;
    padding: 1px 6px;
    padding-bottom: 8px;
    // z-index: 99;
    
    &:hover{
      
      background-color: #0b9444;
      border: 5px #FFF solid;
      color: #FFF;
      filter: drop-shadow(0 0.25rem 0.75rem gray);
    }
  }


.mymodal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255, .8);
  z-index: 99999;
  width: 100%;
  height: 100%;
  .closeModal {
    position: absolute;
    top: 5%;
    right: 8.5%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    // background: red;
    border: unset;
    color: white;
    font-size: 20px;
    z-index: 9999999;
  }
  .mymodal-wrapper {
    .deleteModalButton {
      height: 50px;
      width: 50px;
      border: unset;
      color: white;
      
    }
    .editModalButton {
      height: 50px;
      width: 50px;
      border: unset;
      color: white;
   
    }
    form {
      margin: 0 auto;
      width: 80%;
    }

    margin: 0 auto;
    margin-top: 50px;
    width: 80%;
    max-height: 80%;
    // overflow-y: scroll;
    overflow-x: scroll;
    filter: drop-shadow(0 0 0.75rem gray);
    // background-color: #0b9444;
    background-color: white;
    z-index: 999999;
  }
}

.editButton {
  background: unset;
  color: #0b9444;
  border: unset;
  transition: .3s ease-in-out;
  &:hover {
    font-size: 18px;
  }
}

.change-image-btn {
  position: absolute;
  left: 43%;
  bottom: 0;
  border: none;
  // border-radius: 50%;
  width: 30px;
  height: 30px;
  // padding: 10px;
}

.hideme{
  display: none;
  transition: ease-in-out .6s;

}
.showme{
  transition: ease-in-out .3s;

  &:hover{
    .hideme {
      display: inline-block;
    }

  }
}