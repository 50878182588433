.gloader {
    position: fixed;
    z-index: 999999;
    top:0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.838);
    .data {
        position: absolute;
        text-align: center;
        width: 100%;
        // margin: 0 auto;
        color: #0b9444;
        bottom: 20px;
        // left: calc(1vw + 43%);
    }
    .lds-ring {
        padding-top: 200px;
        display: inline-block;
        position: relative;
        width: 100px;
        height: 100px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 8px;
        border: 8px solid #0b9444;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #0b9444 transparent transparent transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      
}