.login {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    // text-align: center;
    margin: 0 auto;
    .login-wraper {
        margin: 0 auto;
        margin-top: 20px;
        max-width: 500px;
        // background-color: green;
        border-radius: 10px;
        padding: 20px;
        input, button { width: 100%; border-radius: 5px; margin-bottom: 10px; border: 2px green solid; padding: 10px;}
        button {
            width: 70%;
            transition: .3s ease-in-out;
            background-color: green;
            color: white;
            &:hover {
                width: 100%;
                color: white;
            }
        }
    }
}